import { Home, About, OldSlugRedirect } from '../containers';

export const routes = [
  {
    title: 'Dashboard',
    path: '/',
    component: <Home />,
    protected: true,
  },
  {
    title: 'WorkSlug',
    path: '/:workSlug',
    component: <Home />,
    protected: true,
  },
  {
    title: 'WorkSlug',
    path: '/about',
    component: <About />,
    protected: true,
  },
  {
    title: 'OldSlug',
    path: '/work/new/the-bird-was-just-a-bird',
    component: <OldSlugRedirect />,
    protected: true,
  },
];
