import style from './aboutbar.module.scss';

const AboutBar = () => {
  return (
    <div className={style.aboutBar}>
      <a href="/about">about</a>
    </div>
  );
};

export default AboutBar;
