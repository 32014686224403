import style from "./sidemenu.module.scss";

const SideMenu = ({ categoryList, selected, click }) => {
  return (
    <div className={style.sideMenu}>
      <div className={style.wrapper}>
        {categoryList.slice(0, 2).map((item) => {
          return (
            <button
              className={
                selected.title === item.category.title ? style.selected : null
              }
              onClick={() => click(item.category.title)}
            >
              {item.category.title}
            </button>
          );
        })}
      </div>
      <div className={style.wrapper}>
        {categoryList.slice(2, 5).map((item) => {
          return (
            <button
              className={
                selected.title === item.category.title ? style.selected : null
              }
              onClick={() => click(item.category.title)}
            >
              {item.category.title}
            </button>
          );
        })}
      </div>
      <div className={style.wrapper}>
        {categoryList.slice(5, 6).map((item) => {
          return (
            <button
              className={
                selected.title === item.category.title ? style.selected : null
              }
              onClick={() => click(item.category.title)}
            >
              {item.category.title}
            </button>
          );
        })}
      </div>
      <div className={style.wrapper}>
        {categoryList.slice(6, 7).map((item) => {
          return (
            <button
              className={
                selected.title === item.category.title ? style.selected : null
              }
              onClick={() => click(item.category.title)}
            >
              {item.category.title}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default SideMenu;
