const initialState = {
  elementWidth: 180,
};

const elementWidthReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CHANGE_WIDTH':
      return {
        ...state,
        elementWidth: action.payload,
      };

    default:
      return state;
  }
};

export default elementWidthReducer;
