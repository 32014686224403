import React, { useEffect } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { routes } from './routes';
import { ProtectedRoute } from './routes/ProtectedRoute';
import ScrollTop from './helpers/ScrollTop';
import axios from 'axios';
import { BackendUrl } from './constants/ApiConfig';
import { ApiRoutes } from './constants/ApiRoutes';
export const history = createBrowserHistory();

const App = () => {
  const isSeen = localStorage.getItem('isSeen');

  useEffect(() => {
    if (!isSeen) {
      axios
        .get(`${BackendUrl}${ApiRoutes.website.view}`)
        .then(res => {
          console.log(res.data);
          localStorage.setItem('isSeen', true);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [isSeen]);

  return (
    <>
      <BrowserRouter>
        <ScrollTop />
        <Routes>
          {routes.map(route => {
            return (
              <Route
                path={route.path}
                element={
                  route.protected ? (
                    <ProtectedRoute>{route.component}</ProtectedRoute>
                  ) : (
                    route.component
                  )
                }
              />
            );
          })}
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
