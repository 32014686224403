import style from './about.module.scss';
import { Header } from '../../components';
import useWindowDimensions from '../../helpers/windowWidth';
import { WorkBar, AboutBar } from '../../components';
import { useEffect, useState } from 'react';
import { BackendUrl } from '../../constants/ApiConfig';
import { ApiRoutes } from '../../constants/ApiRoutes';
import axios from 'axios';

const About = () => {
  const { width } = useWindowDimensions();
  const [data, setData] = useState();

  useEffect(() => {
    axios
      .get(`${BackendUrl}${ApiRoutes.website.get}`)
      .then(res => {
        console.log(res.data);
        setData({
          aboutLeft: res.data.aboutLeft,
          aboutRight: res.data.aboutRight,
        });
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Header about mobile={width < 481} />
      {width < 481 ? null : (
        <>
          <WorkBar />
          <AboutBar />
        </>
      )}
      {data ? (
        <div className={style.about}>
          <div className={style.wrapper}>
            <div
              dangerouslySetInnerHTML={{ __html: data.aboutLeft }}
              className={style.column}
            ></div>
            <div
              dangerouslySetInnerHTML={{ __html: data.aboutRight }}
              className={style.column}
            ></div>
          </div>
        </div>
      ) : null}
      {width < 481 && data ? (
        <div className={style.socialMediaWrapper}>
          <a href="https://www.instagram.com/kibeleyarman/" target={'_blank'}>
            INSTAGRAM
          </a>
          <a href="mailto:eskyarman@gmail.com" target={'_blank'}>
            CONTACT
          </a>
          <a
            href="https://workingnotworking.com/36913-eren-su-kibele"
            target={'_blank'}
          >
            WORKING NOT WORKING
          </a>
        </div>
      ) : null}
    </>
  );
};

export default About;
