import style from './bottomnav.module.scss';

const BottomNav = ({ data, selected, click }) => {
  return (
    <div className={style.bottomNav}>
      <div className={style.wrapper}>
        {data.map((item, index) => {
          const selectedItem = selected === index;
          const sameCategory =
            !selectedItem &&
            data[selected].category.title === item.category.title;

          return (
            <button onClick={() => click(index)}>
              <span>{selectedItem ? `N°${index + 1}` : ''}</span>
              {selectedItem ? null : (
                <div
                  className={`${style.line} ${
                    sameCategory ? style.selected : null
                  }`}
                ></div>
              )}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default BottomNav;
