import style from './workbar.module.scss';

const WorkBar = () => {
  return (
    <div className={style.workBar}>
      <a href="/">work</a>
    </div>
  );
};

export default WorkBar;
